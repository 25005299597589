import Landing from "./Landing";
import Intro from "./Intro";
import About from "./About";
import Skills from "./Skills";
//import Projects from "./Projects";
//import Outro from "./Outro";
//import Contacts from "./Contacts";
import Blank from "./Blank";

export default function Container() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isiPad = navigator.maxTouchPoints > 0;
  return (
    <div className={`w-screen select-none`}>
      <Landing isMobile={isMobile} isiPad={isiPad}/>
      <Intro isMobile={isMobile} isiPad={isiPad}/>
      <About isMobile={isMobile} isiPad={isiPad}/>
      <Skills isMobile={isMobile} isiPad={isiPad}/>
    </div >
  )
};


//<Skills isMobile={isMobile}/>
//<Projects />
//<Outro isMobile={isMobile}/>
//<Contacts />
