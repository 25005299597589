import Navbar from "./components/Navbar";
import Container from "./sections/Container";

export default function App() {
  return (
    <div>
      <Navbar />
      <Container />
    </div>
  )
};